<template>

  <div class="acd">
    <input id="artist_participants" type="checkbox" class="acd-hidden" @change="expand">
    <label for="artist_participants" class="acd-open first-acd">プラン詳細</label>
    <div class="acd-close items">
      <!-- メインビジュアル -->
      <div style="width: 100%; padding-bottom: 5%; border-bottom: 1px solid #929292; padding: 3%;">
        <div style="padding-bottom: 3%;">メインビジュアル</div>
        <div style="padding-bottom: 3%;">※推奨サイズ：(横:縦)１６:９</div>
        <div class="main-image-container">
          <img style="width: 100%;" :src="getImageSrc(artistThumbnailImage)" :alt="artistThumbnailImage" @click="triggerMainFileInput">
        </div>
        <input type="file" ref="mainFileInput" @change="onFileSelected" style="margin-top: 10px;">
        <div class="send-button">
          <button @click="mainUploadImage" style="margin-top: 3px;">アップロード</button> 
        </div>
        <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p> 
      </div>
      <!-- サムネイル -->
      <div v-if="talk_room" class="artist_participants">
        <div v-for="(payment_plan, index) in talk_room.payment_plans" :key="payment_plan.id" class="payment_plan" style="padding-bottom:3%; border-bottom: 1px solid #929292;">
          <div class="name" style="padding: 3%; font-weight: bold;">{{ payment_plan.name }}</div>

          <div style="display: flex;justify-content: flex-end; align-items: flex-end; height: 100%; padding: 0 3%;">
            <div style="width: 100%;">
              <div style="padding-bottom: 3%;">サムネイル</div>
              <div class="artist_plan_image" style="width: 150px;">
                <img style="width: 100%;" :src="getImageSrc(payment_plan.image)" :alt="payment_plan.image" @click="triggerSubFileInput(index)">
              </div>
              <div style="margin-top: 3rem;">
                <input type="file" :ref="`subFileInput${index}`" @change="onFileDirectSelected($event, payment_plan.id)" style="margin-bottom: 30px;">
              </div>
              <div class="send-button">
                <button @click="subUploadImage(payment_plan.id)" style="margin-top: 3px;">アップロード</button>
              </div>
            </div>
          </div>
          <div class="participant" style="width: 100%;">
            <div>
              <router-link :to="{name: 'artist-participants-list', params: { paymentPlanId: payment_plan.id } }">
                  <span class="count">{{ payment_plan.user_count }}</span>
              </router-link>
              <template v-if="payment_plan.limit_user">
                <span class="separate">/</span>
                <span class="limit_user">{{ payment_plan.limit_user }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ArtistParticipantsList',
  data () {
    return {
      talk_room: null,
      artistThumbnailImage: '',
      selectedFile: null,
      selectedSubFile: null,
      imageURL: '',
      imageSubURL: '',
      errorMessage: '',
      talk_room_user: null,
    }
  },
  methods: {
    expand() {
      const elm = document.getElementById('artist_participants');
      if (elm.checked) {
        this.loadArtistTalkRoom();
      }
    },
    async loadArtistTalkRoom () {
      const url = `${process.env.VUE_APP_API_ROOT}artists/` + this.$store.state.loginUser.artist_id;
      const response = await this.$http.get(url);

      this.talk_room = response.data.talk_room;
      this.artistThumbnailImage = response.data.image;
    },
    getImageSrc(imagePath) {
      return `${process.env.VUE_APP_API_ROOT}../${imagePath}`;
    },
    triggerMainFileInput() {
      this.$refs.mainFileInput.click();
    },
    triggerSubFileInput(index) {
      if (this.$refs[`subFileInput${index}`]) {
        this.$refs[`subFileInput${index}`][0].click();
      }
    },
    onFileSelected(e) {
      const file = e.target.files[0];
      if (!file) return;
      this.selectedFile = file;
      this.imageURL = URL.createObjectURL(file);
      this.errorMessage = ''; // エラーメッセージをリセット
    },
    onFileDirectSelected(event, paymentId) {
      const file = event.target.files[0];
      if (!file) return;
      this.selectedSubFile = file;
      this.imageSubURL = URL.createObjectURL(file);
      this.errorMessage = ''; // エラーメッセージをリセット
      console.log(paymentId)
      // this.subUploadImage(paymentId);
    },
    checkFile(file) {
      const SIZE_LIMIT = 10000000; // 10MB
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        return '許可されていないファイル形式です。JPEGまたはPNGのみアップロード可能です。';
      }
      if (file.size > SIZE_LIMIT) {
        return 'ファイルサイズが大きすぎます。10MB以下のファイルのみアップロード可能です。';
      }
      return ''; // 問題なければ空文字を返す
    },

    // メインビジュアル
    async mainUploadImage() {
      
      if (!this.selectedFile) {
        this.errorMessage = 'ファイルが選択されていません。';
        return;
      }
      const validationError = this.checkFile(this.selectedFile);
      if (validationError) {
        this.errorMessage = validationError;
        return;
      }

      // Base64にエンコード
      const picture = await this.getBase64(this.selectedFile);

      try {
        this.$store.commit('setLoading');
        const url = `${process.env.VUE_APP_API_ROOT}artists/main_image`;
        const response = await this.$http.post(url,{id: this.talk_room.id, image_b64: picture});
        if (response.status === 200) {
          this.artistThumbnailImage = response.data.image;
        } else {
          this.errorMessage = '画像のアップロードに失敗しました。';
        }
      } catch (error) {
        this.errorMessage = 'サーバーへのリクエストに失敗しました。';
      } finally {
        this.$store.commit('resetLoading');
      }
    },

    // サブビジュアル
    async subUploadImage(paymentId) {
      if (!this.selectedSubFile) {
        this.errorMessage = 'ファイルが選択されていません。';
        return;
      }
      const validationError = this.checkFile(this.selectedSubFile);
      if (validationError) {
        this.errorMessage = validationError;
        return;
      }
      // Base64にエンコード
      const picture = await this.getBase64(this.selectedSubFile);

      // サーバー処理
      try {
        this.$store.commit('setLoading');
        const url = `${process.env.VUE_APP_API_ROOT}artists/sub_image`;
        const response = await this.$http.post(url, { talkRoomId: this.talk_room.id, paymentId: paymentId, image_b64: picture });
        if (response.status === 200) {
          if (this.talk_room && this.talk_room.payment_plans.length > 0) {
            this.talk_room.payment_plans = this.talk_room.payment_plans.map(plan => {
              if (plan.id === paymentId) { // 一致するpaymentIdを持つプランのみ更新
                return {
                  ...plan,
                  image: response.data.image  // ここで新しい画像URLを設定
                };
              }
              return plan;  // 他のプランはそのまま
            });
          }
        } else {
          this.errorMessage = '画像のアップロードに失敗しました。';
        }
      } catch (error) {
        this.errorMessage = 'サーバーへのリクエストに失敗しました。';
      } finally {
        this.$store.commit('resetLoading');
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
  }
}
</script>

<style scoped>
.artist_participants {
  margin-bottom: 15px;
}
.participant {
  text-align: right;
}
.participant .count {
  font-size: 34rem;
  margin-right: 5px;
}
.participant .separate {
  margin-right: 5px;
  color: #929292;
}
.first-acd {
  border-top: solid;
}
.main-image-container {
    width: 300px;
    text-align: center;
    margin: 0 auto;
}

.send-button button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 300px;
}
</style>

